import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/element.js'
// 引入css 自适应高度
import './assets/css/global.css'

Vue.config.productionTip = false
import './utils/url'
import {
	myPost,
	myGet,
	myHttp,
	newPost,
	newGet,
	newDelete
} from './utils/http'
import axios from "axios";

Vue.prototype.$newPost = newPost
Vue.prototype.$newGet = newGet
Vue.prototype.$newDelete = newDelete
Vue.prototype.$post = myPost
Vue.prototype.$get = myGet
Vue.prototype.$ajax = myHttp
Vue.prototype.$http = axios


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
