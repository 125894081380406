<template>
  <div style="height: 100%;">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
</style>
