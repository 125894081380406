<template>
  <div class="map-container">
    <!-- 地图   -->
    <div class="box">
		该板块正在建设中。。。
    </div>
  </div>
</template>

<script>
// import geojson1 from '../static/geojson1.json'
// import geojson7 from '../static/geojson7.json'
// import geojson2 from '../static/test3.json'

export default {
  name: "about",
  data() {
    return {
      map: null,
      heatmap: null
    }
  },
  methods: {
   
  },
  mounted()
  {
    setTimeout(() => {
      // this.mymap()
    }, 1000);
  }

}
</script>
<style scoped lang="scss">
.map-container {
  width: 100%;
  height: 100vh;
  .box {
    width: 100%;
    height: 100%;
	background-color: #ffffff;
	font-size: 30px;
	box-sizing: border-box;
	padding-left: 20px;
    #container{
      width: 100%;
      height: 100%;
    }
  }
}

</style>
